<template>
  <q-form ref="editForm">
    <c-card title="화학물질 컬럼 정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="columnSetting"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    위험성평가
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="끓는점"
                    falseLabel="끓는점"
                    name="boilingPoint"
                    v-model="columnSetting.boilingPoint"
                  />
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="위험문구/유해위험문구 등급"
                    falseLabel="위험문구/유해위험문구 등급"
                    name="hrCodeGrade"
                    v-model="columnSetting.hrCodeGrade"
                  />
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="유해･위험문구"
                    falseLabel="유해･위험문구"
                    name="hcode"
                    v-model="columnSetting.hcode"
                  />
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="위험문구"
                    falseLabel="위험문구"
                    name="rcode"
                    v-model="columnSetting.rcode"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="CMR 물질 여부"
                    falseLabel="CMR 물질 여부"
                    name="cmrFlag"
                    v-model="columnSetting.cmrFlag"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="발암성물질"
                    falseLabel="발암성물질"
                    name="carcinogens"
                    v-model="columnSetting.carcinogens"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="변이원성물질"
                    falseLabel="변이원성물질"
                    name="mutagens"
                    v-model="columnSetting.mutagens"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="생식독성물질"
                    falseLabel="생식독성물질"
                    name="reproductiveToxins"
                    v-model="columnSetting.reproductiveToxins"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="유해성 발생형태"
                    falseLabel="유해성 발생형태"
                    name="maleficenceForm"
                    v-model="columnSetting.maleficenceForm"
                  />
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="유해성 측정값"
                    falseLabel="유해성 측정값"
                    name="maleficenceValue"
                    v-model="columnSetting.maleficenceValue"
                  />
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="비산성 등급"
                    falseLabel="비산성 등급"
                    name="maleficenceGrade"
                    v-model="columnSetting.maleficenceGrade"
                  />
                  <c-checkbox
                    :disabled="true"
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="비산/휘발성 구분"
                    falseLabel="비산/휘발성 구분"
                    name="maleficenceType"
                    v-model="columnSetting.maleficenceType"
                  />
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    유해화학물질
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="제한사항"
                    falseLabel="제한사항"
                    name="restrictions"
                    v-model="columnSetting.restrictions"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="금지사항"
                    falseLabel="금지사항"
                    name="prohibited"
                    v-model="columnSetting.prohibited"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="사고대비물질_제조사용 지정수량(Kg)"
                    falseLabel="사고대비물질_제조사용 지정수량(Kg)"
                    name="accidentManuQuantity"
                    v-model="columnSetting.accidentManuQuantity"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="사고대비물질_보관저장 지정수량(kg)"
                    falseLabel="사고대비물질_보관저장 지정수량(kg)"
                    name="accidentStoreQuantity"
                    v-model="columnSetting.accidentStoreQuantity"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    제외조건
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="내용"
                    falseLabel="내용"
                    name="exclusionConditions"
                    v-model="columnSetting.exclusionConditions"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="부등호"
                    falseLabel="부등호"
                    name="exclusionConditionSign"
                    v-model="columnSetting.exclusionConditionSign"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="정량값"
                    falseLabel="정량값"
                    name="exclusionConditionQuantity"
                    v-model="columnSetting.exclusionConditionQuantity"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="단위"
                    falseLabel="단위"
                    name="exclusionConditionUnit"
                    v-model="columnSetting.exclusionConditionUnit"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    PRTR
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="Group_ID"
                    falseLabel="Group_ID"
                    name="prtrGroupId"
                    v-model="columnSetting.prtrGroupId"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="물질명"
                    falseLabel="물질명"
                    name="prtrMaterialName"
                    v-model="columnSetting.prtrMaterialName"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상무게함유기준(wt%)"
                    falseLabel="조사대상무게함유기준(wt%)"
                    name="prtrSwcsWt"
                    v-model="columnSetting.prtrSwcsWt"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상무게함유기준_부등호"
                    falseLabel="조사대상무게함유기준_부등호"
                    name="prtrSwcsWtSign"
                    v-model="columnSetting.prtrSwcsWtSign"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상무게함유기준_정량값"
                    falseLabel="조사대상무게함유기준_정량값"
                    name="prtrSwcsWtQuantity"
                    v-model="columnSetting.prtrSwcsWtQuantity"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상무게함유기준_단위"
                    falseLabel="조사대상무게함유기준_단위"
                    name="prtrSwcsWtUnit"
                    v-model="columnSetting.prtrSwcsWtUnit"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상취급량기준(톤/년)"
                    falseLabel="조사대상취급량기준(톤/년)"
                    name="prtrSwcsTon"
                    v-model="columnSetting.prtrSwcsTon"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상취급량기준_부등호"
                    falseLabel="조사대상취급량기준_부등호"
                    name="prtrSwcsTonSign"
                    v-model="columnSetting.prtrSwcsTonSign"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상취급량기준_정량값"
                    falseLabel="조사대상취급량기준_정량값"
                    name="prtrSwcsTonQuantity"
                    v-model="columnSetting.prtrSwcsTonQuantity"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="조사대상취급량기준_단위"
                    falseLabel="조사대상취급량기준_단위"
                    name="prtrSwcsTonUnit"
                    v-model="columnSetting.prtrSwcsTonUnit"
                  />
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    위험물
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="위험물내용"
                    falseLabel="위험물내용"
                    name="dangerousContents"
                    v-model="columnSetting.dangerousContents"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="위험물유별구분"
                    falseLabel="위험물유별구분"
                    name="dangerousClass"
                    v-model="columnSetting.dangerousClass"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="위험물성질"
                    falseLabel="위험물성질"
                    name="dangerousProp"
                    v-model="columnSetting.dangerousProp"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="위험물품명"
                    falseLabel="위험물품명"
                    name="dangerousName"
                    v-model="columnSetting.dangerousName"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="지정수량_정량값"
                    falseLabel="지정수량_정량값"
                    name="designatedQuantityDose"
                    v-model="columnSetting.designatedQuantityDose"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="지정수량_단위"
                    falseLabel="지정수량_단위"
                    name="designatedQuantityUnit"
                    v-model="columnSetting.designatedQuantityUnit"
                  />
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    노출기준
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="공정안전관리 제조∙취급∙저장량(kg/일)"
                    falseLabel="공정안전관리 제조∙취급∙저장량(kg/일)"
                    name="psmAmount"
                    v-model="columnSetting.psmAmount"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="생물학적노출기준(단위포함 Full text)"
                    falseLabel="생물학적노출기준(단위포함 Full text)"
                    name="bei"
                    v-model="columnSetting.bei"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="과민성 여부 입력(고용노동부, ACGIH 등)"
                    falseLabel="과민성 여부 입력(고용노동부, ACGIH 등)"
                    name="sensitization"
                    v-model="columnSetting.sensitization"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="질식제 여부 입력(ACGIH, EU 노출기준 등)"
                    falseLabel="질식제 여부 입력(ACGIH, EU 노출기준 등)"
                    name="simpleAsphyxiants"
                    v-model="columnSetting.simpleAsphyxiants"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="피부영향 여부 입력(고용노동부, ACGIH 등)"
                    falseLabel="피부영향 여부 입력(고용노동부, ACGIH 등)"
                    name="skinNotations"
                    v-model="columnSetting.skinNotations"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="표적장기 입력(예, 간, 신장 등)"
                    falseLabel="표적장기 입력(예, 간, 신장 등)"
                    name="basisCriticalEffects"
                    v-model="columnSetting.basisCriticalEffects"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="TWA 노출기준입력(only 숫자만) ppm"
                    falseLabel="TWA 노출기준입력(only 숫자만) ppm"
                    name="twaPpm"
                    v-model="columnSetting.twaPpm"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="TWA 노출기준입력(only 숫자만) mg/m3"
                    falseLabel="TWA 노출기준입력(only 숫자만) mg/m3"
                    name="twaMm"
                    v-model="columnSetting.twaMm"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="STEL 노출기준입력(only 숫자만) ppm"
                    falseLabel="STEL 노출기준입력(only 숫자만) ppm"
                    name="stelPpm"
                    v-model="columnSetting.stelPpm"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="STEL 노출기준입력(only 숫자만) mg/m3"
                    falseLabel="STEL 노출기준입력(only 숫자만) mg/m3"
                    name="stelMm"
                    v-model="columnSetting.stelMm"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="Celling 노출기준입력(only 숫자만) ppm"
                    falseLabel="Celling 노출기준입력(only 숫자만) ppm"
                    name="ceilingsPpm"
                    v-model="columnSetting.ceilingsPpm"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="Celling 노출기준입력(only 숫자만) mg/m3"
                    falseLabel="Celling 노출기준입력(only 숫자만) mg/m3"
                    name="ceilingsMm"
                    v-model="columnSetting.ceilingsMm"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="LEL노출기준입력(only 숫자만) ppm"
                    falseLabel="LEL노출기준입력(only 숫자만) ppm"
                    name="lelPpm"
                    v-model="columnSetting.lelPpm"
                  />
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="LEL노출기준입력(only 숫자만) mg/m3"
                    falseLabel="LEL노출기준입력(only 숫자만) mg/m3"
                    name="lelMm"
                    v-model="columnSetting.lelMm"
                  />
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            isDense
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    그 외
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="고시내용 외 참조내용 입력(예, CMR, PBT 등)"
                    falseLabel="고시내용 외 참조내용 입력(예, CMR, PBT 등)"
                    name="remark1"
                    v-model="columnSetting.remark1"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="적용기준, 고시내용 외 추가 참조사항 입력"
                    falseLabel="적용기준, 고시내용 외 추가 참조사항 입력"
                    name="remark2"
                    v-model="columnSetting.remark2"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    trueLabel="기타 참조사항"
                    falseLabel="기타 참조사항"
                    name="remark3"
                    v-model="columnSetting.remark3"
                  />
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemBaseColumnSetting',
  data() {
    return {
      columnSetting: {
        boilingPoint: 'Y',  // 끓는점
        cmrFlag: 'Y',  // CMR 물질 여부
        carcinogens: 'N',  // 발암성물질
        mutagens: 'N',  // 변이원성물질
        reproductiveToxins: 'N',  // 생식독성물질
        maleficenceForm: 'Y',  // 유해성 발생형태
        maleficenceValue: 'Y',  // 유해성 측정값
        maleficenceGrade: 'Y',  // 비산성 등급
        maleficenceType: 'Y',  // 비산/휘발성 구분
        hrCodeGrade: 'Y',  // 위험문구/유해위험문구 등급
        hcode: 'Y',  // 유해･위험문구
        rcode: 'Y',  // 위험문구
        restrictions: 'N',  // 제한사항
        prohibited: 'N',  // 금지사항
        accidentManuQuantity: 'N',  // 사고대비물질_제조사용 지정수량(Kg)
        accidentStoreQuantity: 'N',  // 사고대비물질_보관저장 지정수량(kg)
        exclusionConditions: 'N',  // 제외조건내용
        exclusionConditionSign: 'N',  // 제외조건_부등호
        exclusionConditionQuantity: 'N',  // 제외조건_정량값
        exclusionConditionUnit: 'N',  // 제외조건_단위
        prtrGroupId: 'N',  // PRTR_Group_ID
        prtrMaterialName: 'N',  // PRTR_물질명
        prtrSwcsWt: 'N',  // PRTR_조사대상무게함유기준(wt%)
        prtrSwcsWtSign: 'N',  // PRTR_조사대상무게함유기준_부등호
        prtrSwcsWtQuantity: 'N',  // PRTR_조사대상무게함유기준_정량값
        prtrSwcsWtUnit: 'N',  // PRTR_조사대상무게함유기준_단위
        prtrSwcsTon: 'N',  // PRTR_조사대상취급량기준(톤/년)
        prtrSwcsTonSign: 'N',  // PRTR_조사대상취급량기준_부등호
        prtrSwcsTonQuantity: 'N',  // PRTR_조사대상취급량기준_정량값
        prtrSwcsTonUnit: 'N',  // PRTR_조사대상취급량기준_단위
        remark1: 'N',  // 고시내용 외 참조내용 입력(예, CMR, PBT 등)
        dangerousContents: 'N',  // 위험물내용
        dangerousClass: 'N',  // 위험물유별구분
        dangerousProp: 'N',  // 위험물성질
        dangerousName: 'N',  // 위험물품명
        designatedQuantityDose: 'N',  // 지정수량_정량값
        designatedQuantityUnit: 'N',  // 지정수량_단위
        psmAmount: 'N',  // 공정안전관리 제조∙취급∙저장량(kg/일)
        bei: 'N',  // 생물학적노출기준(단위포함 Full text)
        sensitization: 'N',  // 과민성 여부 입력(고용노동부, ACGIH 등)
        simpleAsphyxiants: 'N',  // 질식제 여부 입력(ACGIH, EU 노출기준 등)
        skinNotations: 'N',  // 피부영향 여부 입력(고용노동부, ACGIH 등)
        basisCriticalEffects: 'N',  // 표적장기 입력(예, 간, 신장 등)
        twaPpm: 'N',  // TWA 노출기준입력(only 숫자만) ppm
        twaMm: 'N',  // TWA 노출기준입력(only 숫자만) mg/m3
        stelPpm: 'N',  // STEL 노출기준입력(only 숫자만) ppm
        stelMm: 'N',  // STEL 노출기준입력(only 숫자만) mg/m3
        ceilingsPpm: 'N',  // Celling 노출기준입력(only 숫자만) ppm
        ceilingsMm: 'N',  // Celling 노출기준입력(only 숫자만) mg/m3
        lelPpm: 'N',  // LEL노출기준입력(only 숫자만) ppm
        lelMm: 'N',  // LEL노출기준입력(only 숫자만) mg/m3
        remark2: 'N',  // 적용기준, 고시내용 외 추가 참조사항 입력
        remark3: 'N',  // 기타 참조사항
      },
      checkGrid: {
        columns: [
          { name: '1', field: '1', },
          { name: '2', field: '2', },
          { name: '3', field: '3', },
        ],
        data: [{}, {}],
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.base.chemBaseColumnSetting.get.url;
      this.saveUrl = transactionConfig.chm.base.chemBaseColumnSetting.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.detailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.columnSetting, _result.data)
      },);
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.columnSetting.regUserId = this.$store.getters.user.userId;
              this.columnSetting.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th-style
  background: #EEEEEE !important
  font-weight: 700
  width: 100%

.td-style
  width: 16.6%
</style>