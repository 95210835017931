<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="LBL0002500"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 담당부서 -->
          <c-dept
            type="search" 
            label="LBL0002501" 
            name="departmentDeptCd" 
            v-model="searchParam.departmentDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 담당자 -->
          <c-field 
            type="user" 
            label="LBLMANAGER" 
            name="departmentUserId" 
            v-model="searchParam.departmentUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 등급 -->
          <c-select
            type="search"
            codeGroupCd="HAZARD_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="gradeCd"
            label="LBL0002502"
            v-model="searchParam.gradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사결과 -->
          <c-select
            type="search"
            codeGroupCd="HAZARD_RESULT_CD"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="LBL0002503"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사상태 여부 -->
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            codeGroupCd="INSPECTION_SUBJECT_FLAG"
            :editable="editable"
            label="LBL0002504"
            name="inspectionSubjectFlag"
            v-model="searchParam.inspectionSubjectFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 유해위험기계기구 목록 -->
    <c-table
      ref="table"
      title="LBL0002505"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :checkClickFlag="false"
      rowKey="hhmHazardousMachineryId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 등록 -->
            <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="onItemClick" />
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['inspectionSubjectFlag']">
          <q-chip
            v-if="props.row.inspectionSubjectFlag==='A'"
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            outline square
            class="full-size-chip inspectionSubjectFlag-blinking"
            text-color="white"
            icon="alarm">
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
          <q-chip
            v-else
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            outline square
            class="full-size-chip"
            text-color="white">
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "hazard-equipment-class",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:80px', 
            align: "center",
            sortable: true,
          },
          {
            name: "machineryName",
            field: "machineryName",
            // 기계명
            label: "LBL0002507",
            style: 'width:200px', 
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "inspectionSubjectFlag",
            field: "inspectionSubjectFlag",
            // 검사상태
            label: "LBL0002508",
            type: 'custom',
            align: "center",
            sortable: true,
          },
          {
            name: "itemNo",
            field: "itemNo",
            // item 번호
            label: "LBL0002517",
            style: 'width:150px', 
            align: "center",
            sortable: true,
          },
          {
            name: "machineryClassificationName",
            field: "machineryClassificationName",
            // 기계분류
            label: "LBL0002500",
            style: 'width:170px', 
            align: "center",
            sortable: true,
          },
          {
            name: "processName",
            field: "processName",
            // 단위공정
            label: "LBL0002509",
            style: 'width:150px', 
            align: "center",
            sortable: true,
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            // 담당부서
            label: "LBL0002501",
            style: 'width:150px', 
            align: "left",
            sortable: true,
          },
          {
            name: "departmentUserName",
            field: "departmentUserName",
            // 관리자
            label: "LBLMANAGER",
            align: "center",
            sortable: true,
          },
          {
            name: "gradeName",
            field: "gradeName",
            // 등급
            label: "LBL0002502",
            align: "center",
            style: 'width:50px', 
            sortable: true,
          },
          {
            name: "inspectionKindName",
            field: "inspectionKindName",
            // 검사종류
            label: "LBL0002510",
            align: "center",
            sortable: true,
            style: 'width:70px', 
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            // 검사주기
            label: "LBL0002511",
            align: "center",
            sortable: true,
          },
          {
            name: "lastInspectionDate",
            field: "lastInspectionDate",
            // 최근검사일
            label: "LBL0002512",
            align: "center",
            sortable: true,
          },
          {
            name: "nextInspectionDate",
            field: "nextInspectionDate",
            // 차기검사일
            label: "LBL0002513",
            align: "center",
            sortable: true,
          },
          {
            name: "inspCertificateIssuanceDate",
            field: "inspCertificateIssuanceDate",
            // 검사증교부일
            label: "LBL0002514",
            align: "center",
            sortable: true,
          },
          {
            name: "useFlagName",
            field: "useFlagName",
            // 사용여부
            label: "LBLUSEFLAG",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        // relatedLawList: [],
        inspectionSubjectFlag: null,
        resultCd: null,
        gradeCd: null,
        inspectionKindCd: null,
        inspectionCycle: null,
        departmentDeptCd: '',
        departmentUserId: '',
        startYmd: '',
        endYmd: '',
        useFlag: 'Y'
      },
      period: ['', ''],
      editable: true,
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'orange' },
        { stepperMstCd: 'A', stepperMstNm: '지연', colorClass: 'red' },
      ],
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.hhm.hazard.check.list.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "LBL0002515"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.hhmHazardousMachineryId ? row.hhmHazardousMachineryId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0002515'; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
<style>
.inspectionSubjectFlag-blinking {
  -webkit-animation:inspectionSubjectFlag-blink 3s ease-in-out infinite alternate;
  -moz-animation:inspectionSubjectFlag-blink 3s ease-in-out infinite alternate;
  animation:inspectionSubjectFlag-blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes inspectionSubjectFlag-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes inspectionSubjectFlag-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes inspectionSubjectFlag-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>